<template>
    <div > 
 
        <div style=" height:82vh;overflow-y:auto;">
            <div style=" margin-bottom:1px;background:#67c23a;line-height:40px;height:40px; padding-left:15px;color:black">
                <span style="font-size:14px">网络优化明细  ( 提示：保存后将会改变前端界面,图片大小：230x130 )
                    <el-link :underline="false" @click="addCert"><i class="iconfont icon-xinzeng" style="padding-left:15px;font-size:14px;color:white">【新增记录】</i></el-link>
 
                </span>
                <div>
                <el-table  :data="certData" 
                    border                            
                    size="small"
                    :height="tabHigh" 
                    :row-key="getRowKey"
                    ref="refdtl"
                    highlight-current-row > 
                    <span v-for="(item,index ) in certCol" :key="index" > 
                        <el-table-column   v-if="item.THIDE=='N'"
                            :prop="item.FLDNO"
                            :fixed="item.key==='SORTBY'"
                            :label="item.FLDNM"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :min-width="item.width">
                        </el-table-column>    
                    </span> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="160">
                        <template slot-scope="scope">
                            <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="certEdit(scope.row,scope.$index )">编辑</el-button>
                            <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="certDel(scope.row,scope.$index )">删除</el-button>  
                        </template>
                    </el-table-column>  
                </el-table>
                </div>
            </div>
        </div>
 
        <!--  认证工具 -->
        <el-dialog  :show-close="false" v-dialogDrag :visible.sync="certWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{certFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <Form :model="certRow" :label-width="120"  label-position="right" inline :rules="ruleValidate">  
                <span v-for="(item,index) in certCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="certRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-select v-else-if="item.FLDNO==='SUBTY'" @change="subChg" v-model="certRow[item.FLDNO]" placeholder="请选择" clearable  style="width:100%; ">
                            <span v-for="item in tyArr" :key='item.index'>
                                <el-option :label="item.CNAME" :value="item.CODE"></el-option>
                            </span>
                        </el-select>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="certRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="display:flex;padding-left:120px;">
                    <div style=" margin-top:20px;">                   
                        <el-row>
                            <el-image  :src="certPath" style="width:100%;height:120px;margin-right:20px;" ></el-image>
                        </el-row> 
                        <el-row > 
                            <el-link   :underline="false" @click="imgPreview('cert')"><i class="iconfont icon-chazhao" style="margin-left:90px" />  </el-link>
                        </el-row>                                                                                                
                    </div>
                    <div  style="margin-left:20px;;margin-top:20px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="successImgFile"
                                    :on-progress="handleProgress"
                                    :accept="Accept"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="1024"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="uploadUrl"
                                    type="drag"
                                    style="display: inline-block;width:90px; ">                           
                                    <div style="padding: 1px 0;width:120px;height:120px;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;margin-right:25px;" @click="upType('cert')"></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
 
                    </div> 
                </div>
            </Form>
            <div style="height:40px;line-height:30px;display:flex;justify-content:center;border-top:0px solid #ccc;padding-top:4px; ">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="certWin=false" style="margin-right:50px;font-size:16px;">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="certAppend"  style="margin-right:10px;font-size:16px;">确定</el-button>
            </div>
        </el-dialog>
 
        
        <!-- 预览图片 -->
        <el-dialog :visible.sync="showViewer" width="950px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'40px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:15px;">图片预览</span>
            </div>
            <el-image fit="scale-down" :src="prevPath"  style="width:99%;height:400px;margin-top:5px;"></el-image> 
        </el-dialog>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
            </div>
            <div style=" margin:15px 10px">{{prompt}}</div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 上传进度 -->
        <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:15px;">正在上传中...</span>
            </div>
            <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
        </el-dialog>
 
    </div>  
</template>
<script>
 
 
import { getBaseData,getTabColOrData } from '../../api/user'
import {  paramData } from '../../api/Select' 
import {  uuid } from '../../api/Tools' 
//数组元素重新按某个属性排序
var compareObj=function(prop){
    return function(obj1,obj2){
        var val1 =obj1[prop]?Number(obj1[prop]):0
        var val2 =obj2[prop]?Number(obj2[prop]):0
        if (val1<val2){
            return -1
        }else if(val1>val2){
            return 1
        }else{
            return 0
        }
    }
}
//局部注册
export default {
    name:"trade_manage",
    data () {
        return {
            stfgBool:true,
            tyArr:[],
            supWin:false,
            authPic:false,
            ruleValidate:{},
            Accept: "png,jpg,jpeg", //上传文件格式限制
            uploadData:{}, //上传数据
            videoFlag:false,
            videoUploadPercent:0,
            upFlag:'',
            uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_username')+'&password='+this.$cookies.get('v_password'),
            certPath:'',
            ftyPath:'',
            authPath:'',
            showViewer:false,
            prevPath:'',
            certCol:[],
            certData:[],
            certWin:false,
            certFlag:'create',
            certIndex:'',
            certRow:{},
            certBef:{},
            delCertArr:[],
            v_url:this.$store.state.queryUrl , //api请求路径    
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            promptWin:false,
            prompt:'',
            notNull:[],
            advertPath:'',
            adPath:'',
            subNm:'',
        }
    },
    mounted () {   
        this.getCertList()
        //动态生成表单验证规则
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','41352','fty','HEAD','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            this.notNull = res.data
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空'}
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.ruleValidate =ruleObj
        });
        // //认证类别类型
        paramData('parentno','41398' ,'V_sys_base').then((res)=>{               
            this.tyArr =res.data.result
        }); 
    },
    computed: {
 
        tabHigh: function() {
            return (window.innerHeight - 220) + 'px';
        }, 
    },
    components: {
 
 
 
    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
  
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }     
    },
    watch: {
 
    },
    methods: { 
        subChg(val){
            this.tyArr.find(item=>{
                if (item.CODE==val){
                    return this.subNm =item.CNAME
                }
            })
        },
        upType(val){
            this.upFlag=val
        },
        //认证保存
        saveCert(){
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/tableBodySave?username='+this.v_username +'&password='+this.v_password,
                data: {p_json:JSON.stringify(this.certRow), p_table:'bs_tools',p_idseq:'41352' },
            }).then(res=>{
                if (res.data.code!=='200'){
                    this.promptWin=true
                    this.prompt=res.data.result
                }else{
                    this.getCertList()
                }
            })
        
        },
 
        //政策调研
        addCert(){
            let sort =this.certData.length+1
            this.certRow={IDSEQ:'41399',IDTY:'41398','SORTBY':sort}
            this.certWin=true
            this.certFlag='create'
            this.certPath=''
        },
  
        redoRec(){
            this.$confirm('你确定要取消保存认证记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.getCertList()
            }).catch(()=>{})
        },
 
 
        //文件超出指定文件大小限制的钩子
        handleMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出1M'
            this.promptWin=true
        },
        //文件格式验证错误时的钩子
        handleFormatError (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 jpg or png' 
            this.promptWin=true
        }, 
        // 文件上传成功时的钩子
        successImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            if (this.upFlag=='cert'){
                this.certPath=res.result
                this.certRow['PATH']=res.result
            }  
        },
        //上传显示进度
        handleProgress(event,file,fileList){
            this.videoFlag = true
            this.videoUploadPercent = Math.floor(event.percent)
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) {  
            this.uploadData = {
                p_user:this.v_username,
                p_idseq:  uuid(),
                p_table:'',
                p_filety:'',
            }
            let promise = new Promise(resolve => {
            this.$nextTick(function() {
                    resolve(true);
                });
            });
            return promise;  
        },
        imgPreview(val){
            if (val=='cert'){
                this.prevPath=this.certRow['PATH']
            } 
            this.showViewer=true
        },
        certAppend(){
            this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                if (!this.certRow[item.FLDNO] ){
                    this.stfgBool =false 
                    this.promptWin=true
                    this.prompt =item.FLDNM+'不能为空' 
                    return true
                }else{
                    this.stfgBool =true  
                }
            })
            if (this.stfgBool) { 
                this.saveCert()
                this.certWin=false
            }
        },
 
        getRowKey(row){
            return row.IDSEQ +'-'+row.LSTSEQ
        },
        getTabCol(){
            //certificate
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','41352','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                this.certCol = res.data
            })
 
 
        },
        certDel(row,index){
            this.$confirm('你确定要删除此记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //删除
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ, p_table:'bs_tools'},
                }).then(res=>{
                    this.certData.splice(index,1 )
                })
           }) 
        },
        certEdit(row,index){
            this.certFlag='edit'
            this.certRow=JSON.parse(JSON.stringify(row))
            this.certWin=true
            this.certIndex=index
            this.certBef=row
            this.certPath=row['PATH']
            this.delCertArr=[]
        },
 
        //认证查询明细 
        getCertList(){
            this.certData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'V_BS_TOOLS','idty','41398','','','','',' order by sortby ' ).then((res) => { 
                this.certData =res.data 
            })
            setTimeout(() => {
                this.$refs.refdtl.doLayout();
            }, 500);
        },
 
 
    }
}
</script>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    // .el-dialog__headerbtn i { background:white;font-size: 20px;  }
</style>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .el-dropitem {  
        cursor: pointer;
        font-size:15px;
        color:rgb(143, 114, 114) !important;
        height:50px;
        line-height:50px;
        text-align:center;
    } 
    .el-aside {
        height: calc(100vh - 100px);// vh就是当前屏幕可见高度的1%   只用设置左侧 aside 高度
    }
    .card-div {
        padding: 14px;
        background:#EBEEF5; 
        text-align:center;
    }
    .cardhover:hover{ //如有空格表示同级和子级都会受影响
        border :#409EFF 1px solid;
    }
    .img-div{
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
        line-height:20px;
    }
    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
 
    .group-css{
      display:flex;
      justify-content:space-between;
      flex-wrap:wrap;
      width:1230px;
    }
    .wraper{
        width:1250px;
        margin:1px auto;
    }
 
</style>
 